module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.13.0_gatsby@5.13.1_webpack@5.88.2/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.0_gatsby@5.13.1_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nuklearforum.ch","short_name":"Nuklear Forum","start_url":"/","background_color":"#FFF","theme_color":"#1F4F59","display":"standalone","icon":"./src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b09538b2416c76022d3e79380b4fed1d"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.0_gatsby@5.13.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-7188801-2","G-8K01HN6VJS"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-use-query-params@1.0.1_gatsby@5.13.1_react-dom@18.2.0_react@18.2.0_use-query-params@1.2.3/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.1_@swc+core@1.3.29_babel-eslint@10.1.0_esbuild@0.17.4_react-dom@18.2.0_react@18.2.0_typescript@4.9.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
