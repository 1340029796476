exports.components = {
  "component---2475645319--amazeelabs-gatsby-source-silverback-build-templates-stub-js": () => import("./../../../node_modules/.pnpm/@amazeelabs+gatsby-source-silverback@1.13.8_@types+node@14.18.36_cosmiconfig-toml-loader@1.0._isffqcyg35kmubknvm6oy6aa2a/node_modules/@amazeelabs/gatsby-source-silverback/build/templates/stub.js" /* webpackChunkName: "component---2475645319--amazeelabs-gatsby-source-silverback-build-templates-stub-js" */),
  "component---src-pages-de-suche-tsx": () => import("./../../../src/pages/de/suche.tsx" /* webpackChunkName: "component---src-pages-de-suche-tsx" */),
  "component---src-pages-fr-recherche-tsx": () => import("./../../../src/pages/fr/recherche.tsx" /* webpackChunkName: "component---src-pages-fr-recherche-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/basic-page.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-bulletin-tsx": () => import("./../../../src/templates/bulletin.tsx" /* webpackChunkName: "component---src-templates-bulletin-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-context-tsx": () => import("./../../../src/templates/context.tsx" /* webpackChunkName: "component---src-templates-context-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-fact-sheet-tsx": () => import("./../../../src/templates/fact-sheet.tsx" /* webpackChunkName: "component---src-templates-fact-sheet-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-kernwissen-tags-list-tsx": () => import("./../../../src/templates/kernwissen-tags-list.tsx" /* webpackChunkName: "component---src-templates-kernwissen-tags-list-tsx" */),
  "component---src-templates-kernwissen-tags-tsx": () => import("./../../../src/templates/kernwissen-tags.tsx" /* webpackChunkName: "component---src-templates-kernwissen-tags-tsx" */),
  "component---src-templates-kernwissen-tsx": () => import("./../../../src/templates/kernwissen.tsx" /* webpackChunkName: "component---src-templates-kernwissen-tsx" */),
  "component---src-templates-multimedia-dossier-tsx": () => import("./../../../src/templates/multimedia-dossier.tsx" /* webpackChunkName: "component---src-templates-multimedia-dossier-tsx" */),
  "component---src-templates-my-topics-tsx": () => import("./../../../src/templates/my-topics.tsx" /* webpackChunkName: "component---src-templates-my-topics-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-podcast-tsx": () => import("./../../../src/templates/podcast.tsx" /* webpackChunkName: "component---src-templates-podcast-tsx" */),
  "component---src-templates-press-release-tsx": () => import("./../../../src/templates/press-release.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-topic-collection-tsx": () => import("./../../../src/templates/topic-collection.tsx" /* webpackChunkName: "component---src-templates-topic-collection-tsx" */),
  "component---src-templates-topic-tsx": () => import("./../../../src/templates/topic.tsx" /* webpackChunkName: "component---src-templates-topic-tsx" */),
  "component---src-templates-type-listings-tsx": () => import("./../../../src/templates/type-listings.tsx" /* webpackChunkName: "component---src-templates-type-listings-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

